/* :root {
  color: #f8f6f0;
  primary: #4653f6;
  primarydark: #757cf9;
  primarytext: #212121;
  secondary: #27ae60;
  secondary-dark: #7af294;
  light-text: #767676;
  secondary-focus: #238839;
  border-clr: #d1d5da;
  grey-bg: #e1e4e8;
  minimal-bg: #f8f6f0;
} */

.menu-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.menu-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* ===== custom scrollbar ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #d7d7e6 #ecedf2;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ecedf2;
}

*::-webkit-scrollbar-thumb {
  background-color: #d7d7e6;
  border-radius: 10px;
}
